import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

function Cars() {
  const [show, setShow] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [cars, setCars] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCar, setSelectedCar] = useState(null);
  const [customerId, setCustomerId] = useState("");
  const [verified, setverified] = useState(false);
  const [deleteCar, setDeleteCar] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const verify = async () => {
      try {
        const response = await fetch(
          "https://kamclose.masterybrandambassador.com/verify",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ key: sessionStorage.getItem("key") }),
          }
        );
        if (!response.ok) {
          toast.error("Admin not verified");
          navigate("/");
        }
        setverified(true);
      } catch (error) {
        toast.error("Admin not verified");
        navigate("/");
      }
    };

    if (!verified) verify();
  }, []);

  useEffect(() => {
    if (verified) {
      fetchCustomers();
      fetchCars();
    }
  }, [verified]);

  const fetchCustomers = async () => {
    try {
      const response = await fetch(
        "https://kamclose.masterybrandambassador.com/customers"
      );
      const data = await response.json();
      setCustomers(data);
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  };

  const fetchCars = async () => {
    try {
      const response = await fetch(
        "https://kamclose.masterybrandambassador.com/cars"
      );
      const data = await response.json();
      setCars(data);
    } catch (error) {
      console.error("Error fetching cars:", error);
    }
  };

  const handleClose = () => {
    setShow(false);
    setSelectedCar(null);
  };

  const handleShow = () => setShow(true);

  const handleAddCar = async (event) => {
    event.preventDefault();
    const form = event.target;
    const newCar = {
      customer_id: form.customerId.value,
      car_name: form.carName.value,
      reg_number: form.regNumber.value,
    };

    if (!newCar.customer_id || !newCar.car_name || !newCar.reg_number) {
      toast.error("Enter complete car details");
      return;
    }
    try {
      const response = await fetch(
        "https://kamclose.masterybrandambassador.com/cars",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newCar),
        }
      );
      fetchCars();
      if (response.ok) toast.success("Car added successfully");
      else toast.error("Car already exists for customer");
    } catch (error) {
      toast.error("Car already exists for customer");
    }

    handleClose();
  };

  const handleEditCar = (car) => {
    setSelectedCar(car);
    handleShow();
  };

  const handleUpdateCar = async (event) => {
    event.preventDefault();
    const form = event.target;
    const updatedCar = {
      ...selectedCar,
      car_name: form.carName.value,
      reg_number: form.regNumber.value,
      customer_id: customerId,
    };

    try {
      const response = await fetch(
        `https://kamclose.masterybrandambassador.com/cars/${selectedCar.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedCar),
        }
      );
      fetchCars();
      if (response.ok) toast.success("Car updated successfully");
      else toast.error("Car already exists for customer");
    } catch (error) {
      toast.error("Car already exists for customer");
    }

    handleClose();
  };

  const handleDeleteCar = async (carId) => {
    try {
      const response = await fetch(
        `https://kamclose.masterybrandambassador.com/cars/${carId}`,
        {
          method: "DELETE",
        }
      );
      fetchCars();
      if (response.ok) {
        toast.success("Car deleted successfully");
        setDeleteCar(null);
      } else toast.error("Error deleting car");
    } catch (error) {
      toast.error("Error deleting car");
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredCars = Array.isArray(cars)
    ? cars.filter(
        (car) =>
          car.car_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          car.reg_number.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : [];

  return (
    <div className="container">
      <div className="crud shadow-lg p-3 mb-5 mt-5 bg-body rounded">
        <div className="row ">
          <div className="col-sm-3 mt-5 mb-4 text-gred">
            <div className="search">
              <input
                className="form-control mr-sm-2"
                type="search"
                placeholder="Search Car"
                aria-label="Search"
                onChange={handleSearch}
              />
            </div>
          </div>
          <div className="col-sm-3 offset-sm-2 mt-5 mb-4 text-gred">
            <h2>
              <b>Car Details</b>
            </h2>
          </div>
          <div className="col-sm-3 offset-sm-1  mt-5 mb-4 text-gred">
            <Button variant="primary" onClick={handleShow}>
              Add New Car
            </Button>
          </div>
        </div>
        <div className="row">
          <div className="table-responsive ">
            <table className="table table-striped table-hover table-bordered">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Registration Number</th>
                  <th>Customer</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredCars.map((car) => (
                  <tr key={car.id}>
                    <td>{car.id}</td>
                    <td>{car.car_name}</td>
                    <td>{car.reg_number}</td>
                    <td>{car.customer_name}</td>
                    <td>
                      <a
                        href="#"
                        onClick={() => handleEditCar(car)}
                        className="edit"
                        title="Edit"
                        data-toggle="tooltip"
                      >
                        <i className="material-icons">&#xE254;</i>
                      </a>
                      <a
                        href="#"
                        onClick={() => setDeleteCar(car.id)}
                        className="delete"
                        title="Delete"
                        data-toggle="tooltip"
                        style={{ color: "red" }}
                      >
                        <i className="material-icons">&#xE872;</i>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="model_box">
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>{selectedCar ? "Edit Car" : "Add Car"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form onSubmit={selectedCar ? handleUpdateCar : handleAddCar}>
                <div className="form-group">
                  <label htmlFor="carName">Car Name:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="carName"
                    placeholder="Enter Car Name"
                    defaultValue={selectedCar && selectedCar.car_name}
                  />
                </div>
                <div className="form-group mt-3">
                  <label htmlFor="regNumber">Registration Number:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="regNumber"
                    placeholder="Enter Registration Number"
                    defaultValue={selectedCar && selectedCar.reg_number}
                  />
                </div>
                <div className="form-group mt-3">
                  <label htmlFor="customerId">Select Customer:</label>
                  <select
                    className="form-control"
                    id="customerId"
                    onChange={(e) => setCustomerId(e.target.value)}
                    defaultValue={selectedCar && selectedCar.customer_id}
                  >
                    <option value="">Select Customer</option>
                    {customers.map((customer) => (
                      <option key={customer.id} value={customer.id}>
                        {customer.name}
                      </option>
                    ))}
                  </select>
                </div>
                <button type="submit" className="btn btn-success mt-4">
                  {selectedCar ? "Update Car" : "Add Car"}
                </button>
              </form>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <div className="model_box">
          <Modal
            show={deleteCar}
            onHide={() => setDeleteCar(null)}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Delete Car</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Deleting the car will delete all sales of the car!<br></br>
              Are you sure you want to delete?
            </Modal.Body>

            <Modal.Footer>
              <Button
                variant="danger"
                onClick={() => handleDeleteCar(deleteCar)}
              >
                Delete
              </Button>
              <Button variant="secondary" onClick={() => setDeleteCar(null)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default Cars;
