import html2pdf from "html2pdf.js";
import React from "react";

const Receipt = ({ info }) => {
  const handleDownload = async () => {
    const pdfElement = document.getElementById("receipt");

    if (!pdfElement) {
      console.error("PDF element not found.");
      return;
    }

    const options = {
      margin: 0,
      filename: "receipt.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a5", orientation: "portrait" },
    };

    html2pdf().from(pdfElement).set(options).save();
  };

  const handlePrint = async () => {
    const pdfElement = document.getElementById("receipt");

    if (!pdfElement) {
      console.error("PDF element not found.");
      return;
    }

    const options = {
      margin: 0,
      filename: "receipt.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a5", orientation: "portrait" },
    };
    html2pdf()
      .from(pdfElement)
      .toPdf()
      .get("pdf")
      .then(function (pdf) {
        window.open(pdf.output("bloburl"), "_blank");
      });
  };

  return (
    <>
      <i onClick={handleDownload} className="fa-solid fa-download"></i>
      <i onClick={handlePrint} className="fa-solid fa-print"></i>

      <div id="receipt" className="py-3 px-2">
        <center id="top" className="mb-5">
          <img src="logo.png" alt="" />
        </center>

        <div id="mid">
          <p>
            <strong>Date: </strong>
            <span>{info.date}</span>
          </p>
          <p>
            <strong>Name: </strong>
            <span>{info.name}</span>
          </p>
          <p>
            <strong>Phone: </strong>
            <span>{info.phone}</span>
          </p>
        </div>

        <div id="bot">
          <div id="table">
            <div>
              <p>Oil</p>
              <p>{info.oil}</p>
            </div>
            <div>
              <p>Quantity bought</p>
              <p>{info.quantity} litres</p>
            </div>
            <div>
              <p>Car Name</p>
              <p>{info.carname}</p>
            </div>
            <div>
              <p>Registration No</p>
              <p>{info.regNo}</p>
            </div>
            <div>
              <p>Current Mileage</p>
              <p>{info.current}</p>
            </div>
            <div>
              <p>Mileage at next oil change</p>
              <p>{info.next}</p>
            </div>
            <br></br>
            <div className="tabletitle">
              <h2>Total Amount</h2>
              <h2>Rs. {info.amount}</h2>
            </div>
          </div>
          <br></br>
          <p className="end">Thank you for using our Car Oil Sales Platform!</p>
        </div>
      </div>
    </>
  );
};

export default Receipt;
