import React from 'react'
import { Link, useLocation } from "react-router-dom";
import { Container, Nav, Navbar } from 'react-bootstrap';

const MyNavbar = () => {
    const location = useLocation();
  const { pathname } = location;

  const isActive = (path) => {
    return pathname === path ? "active" : "";
  };

    return (
        <Navbar bg="light" collapseOnSelect expand="lg" className="bg-body-tertiary navbar">
            <Container>
                <img src="logo.png" height={60} alt="Logo" />
                <Navbar.Brand ><Link to='/'>Car Oil Sales</Link></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto"> 
                        <Nav.Link ><Link className={isActive("/home")} to='/home'>Sales</Link></Nav.Link>
                    </Nav>
                    <Nav>

                        <Nav.Link>
                            <Link className={isActive("/cars")} to='cars'>Cars</Link>
                        </Nav.Link>
                        <Nav.Link>
                            <Link className={isActive("/customers")} to='customers'>Customers</Link>
                        </Nav.Link>
                        <Nav.Link>
                            <Link className={isActive("/products")} to='products'>Products</Link>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>  
        </Navbar>
    );
}

export default MyNavbar;